<template>
  <b-modal id="modal-credit-card-verify-modal"
           centered
           hide-header
           hide-footer
           size="sm">
    <div class="title">
      <h3 class="text-dark font-size-secondary mr-3 mb-5">
        {{ $ml.get('verifyCreditCardTitle') }}
      </h3>
      <p><small>{{ $ml.get('verifyCreditCardSubTitle') }}</small></p>
      <p class="secondSubTitle">{{ $ml.get('verifyCreditCardSecondSubTitle') }}</p>
    </div>

    <div class="form mt-3"
         :disabled="loading">
      <b-form-group id="amountGroup"
                    label-for="amount">
        <b-form-input class="mb-5"
                      ref="amountInput"
                      id="amount"
                      v-model="creditCard.amount"
                      @keyup.enter="verifyCreditCard"
                      v-mask="['#.##']"
                      :placeholder="`${$ml.get('amount')}, e.g. 0.87`"></b-form-input>
      </b-form-group>

      <b-button variant="primary"
                block
                :disabled="loading"
                @click="verifyCreditCard">{{ $ml.get('verify') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'CreditCardVerifyModal',
  props: {
    cardId: String,
  },
  data() {
    return {
      creditCard: {
        amount: null,
      },
      loading: false,
    }
  },
  methods: {
    verifyCreditCard() {
      if (this.creditCard.amount == '' || this.creditCard.amount == null) {
        this.generateToast('danger', this.$ml.get('invalidAmount'))
        return false
      }

      this.loading = true
      ApiService.delete(
        `credit-cards/${this.cardId}/verification?amount=${this.creditCard.amount}`
      )
        .then((res) => {
          this.loading = false
          this.generateToast('success', this.$ml.get('verifyCreditCardSuccess'))
          this.$bvModal.hide('modal-credit-card-verify-modal')
          this.$emit('verifyCreditCard')
        })
        .catch((err) => {
          this.loading = false
          this.creditCard.amount = ''
          this.$refs.amountInput.focus()

          if (err.response && err.response.status == 400) {
            this.generateToast('danger', err.response.data.error.message)
          } else {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  watch: {
    cardId() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.amountInput.focus()
        }, 0)
      })
    },
  },
}
</script>

<style lang="scss">
#modal-credit-card-verify-modal {
  .form-control {
    border-radius: 16px;
    border-color: transparent;
  }
  .secondSubTitle {
    font-size: 10px;
    color: #3f4254;
    font-style: italic;
    margin-top: 10px;
  }
}
</style>
