<template>
  <b-modal id="modal-addcard"
           centered
           hide-header
           hide-footer
           size="sm">
    <div class="title">
      <h3 class="text-dark font-size-secondary float-left mr-3 mb-5">
        {{
          this.selectedCard.id
            ? $ml.get('updateCard')
            : $ml.get('addNewCard')
        }}
      </h3>
      <div class="d-flex card-brand-logo">
        <div class="ml-1">
          <img src="/image/visa.png"
               class="img-fluid"
               :class="cardBrand == 'visa' ? 'selected-card-type' : ''"
               alt="visa" />
        </div>
        <div class="ml-1">
          <img src="/image/mastercard.png"
               class="img-fluid mr-1"
               :class="cardBrand == 'mastercard' ? 'selected-card-type' : ''"
               alt="mastercard" />
        </div>
        <div class="ml-1">
          <img src="/image/amex.png"
               class="img-fluid"
               :class="cardBrand == 'amex' ? 'selected-card-type' : ''"
               alt="american express" />
        </div>
      </div>
    </div>

    <div class="form"
         :disabled="loading"
         @keyup.enter="addCreditCard">
      <b-form-group id="cardHolderNameGroup"
                    label-for="cardHolderName">
        <b-form-input class="mb-5"
                      id="cardHolderName"
                      v-model="creditCard.holderName"
                      :placeholder="$ml.get('cardHolderName')"></b-form-input>
      </b-form-group>

      <b-form-group id="cardHolderNumberGroup"
                    label-for="cardHolderNumber">
        <b-form-input class="mb-5"
                      id="cardHolderNumber"
                      ref="cardNumInput"
                      v-model="creditCard.cardNumber"
                      v-cardformat:formatCardNumber
                      :placeholder="$ml.get('cardNumber')"></b-form-input>
      </b-form-group>

      <b-form-group id="cardHolderExpDateGroup"
                    label-for="cardHolderExpDate">
        <b-form-input class="mb-5"
                      id="cardHolderExpDate"
                      v-model="creditCard.expDate"
                      v-mask="['##/####']"
                      :placeholder="`${$ml.get('expDate')}, 01/24`"></b-form-input>
      </b-form-group>

      <b-form-group id="cardHolderCVVGroup"
                    label-for="cardHolderCVV">
        <b-form-input class="mb-5"
                      id="cardHolderCVV"
                      v-model="creditCard.cvv"
                      v-cardformat:formatCardCVC
                      :placeholder="$ml.get('cvv')"></b-form-input>
      </b-form-group>

      <b-button variant="primary"
                block
                :disabled="loading"
                @click="addCreditCard">
        {{
          this.selectedCard.id ? $ml.get('update'): $ml.get('addCard')
        }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  name: 'CreditCardModal',
  data() {
    return {
      creditCard: {
        holderName: '',
        cardNumber: '',
        expDate: '',
        cvv: '',
      },
      cardBrand: null,
      $cardFormat: this.$cardFormat,
      loading: false,
    }
  },
  props: {
    selectedCard: Array | null,
  },
  methods: {
    controlFields() {
      if (this.creditCard.holderName == '') {
        this.generateToast(
          'danger',
          this.$ml.get('invalidCreditCardHolderName')
        )
        return false
      }

      if (!this.$cardFormat.validateCardNumber(this.creditCard.cardNumber)) {
        this.generateToast('danger', this.$ml.get('invalidCreditCardNumber'))
        return false
      }

      if (!this.$cardFormat.validateCardExpiry(this.creditCard.expDate)) {
        this.generateToast('danger', this.$ml.get('invalidExpirationDate'))
        return false
      }

      if (!this.$cardFormat.validateCardCVC(this.creditCard.cvv)) {
        this.generateToast('danger', this.$ml.get('invalidCVCNumber'))
        return false
      }

      this.cardBrand = this.$refs.cardNumInput.$el.getAttribute(
        'data-card-brand'
      )

      return true
    },
    addCreditCard() {
      if (this.controlFields()) {
        if (this.selectedCard.id) {
          this.updateCreditCard()
        } else {
          this.newCreditCard()
        }
      }
    },
    newCreditCard() {
      this.loading = true
      ApiService.post('credit-cards', {
        type: this.cardBrand,
        cc_holder_name: this.creditCard.holderName,
        cc_number: this.creditCard.cardNumber.replace(/ /g, ''),
        cc_cvv: this.creditCard.cvv,
        cc_month: this.creditCard.expDate.split('/')[0],
        cc_year:
          this.creditCard.expDate.split('/')[1].length > 2
            ? this.creditCard.expDate.split('/')[1]
            : 20 + this.creditCard.expDate.split('/')[1],
      })
        .then((res) => {
          this.loading = false
          this.generateToast('success', this.$ml.get('addedCreditCardSuccess'))
          this.$bvModal.hide('modal-addcard')
          this.$emit('addedCreditCard')
        })
        .catch((err) => {
          this.loading = false

          if (err == 'Error') {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    updateCreditCard() {
      this.loading = true
      ApiService.put('credit-cards/' + this.selectedCard.id, {
        type: this.cardBrand,
        cc_holder_name: this.creditCard.holderName,
        cc_number: this.creditCard.cardNumber.replace(/ /g, ''),
        cc_cvv: this.creditCard.cvv,
        cc_month: this.creditCard.expDate.split('/')[0],
        cc_year:
          this.creditCard.expDate.split('/')[1].length > 2
            ? this.creditCard.expDate.split('/')[1]
            : 20 + this.creditCard.expDate.split('/')[1],
      })
        .then((res) => {
          this.loading = false
          this.generateToast(
            'success',
            this.$ml.get('updatedCreditCardSuccess')
          )
          this.$bvModal.hide('modal-addcard')
          this.$emit('addedCreditCard')
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.status == 400) {
            this.generateToast('danger', err.response.data.error.message)
          } else {
            this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
          }
        })
    },
    clearCreditCard() {
      this.creditCard.holderName = ''
      this.creditCard.cardNumber = ''
      this.creditCard.expDate = ''
      this.creditCard.cvv = ''
      this.cardBrand = null
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  watch: {
    selectedCard: function (value) {
      if (this.selectedCard !== null) {
        this.creditCard.holderName = this.selectedCard.holder_name
        this.creditCard.cardNumber = this.selectedCard.no
        this.creditCard.expDate =
          this.selectedCard.month + '/' + this.selectedCard.year
        this.creditCard.cvv = ''
        this.cardBrand = this.selectedCard.type
      } else {
        this.clearCreditCard()
      }
    },
  },
}
</script>

<style lang="scss">
#modal-addcard {
  .form-control {
    border-radius: 16px;
    border-color: transparent;
  }

  .card-brand-logo {
    img {
      border: 1px solid transparent;
    }
  }

  img.selected-card-type {
    border: 1px solid #00baf2;
    border-radius: 4px;
  }
}
</style>
