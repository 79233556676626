<template>
  <a class="btn btn-icon btn-light-danger btn-sm rounded-sm px-5">
    <i class="icon-sm fas fa-trash"></i> 
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonDelete',
}
</script>