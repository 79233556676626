<template>
  <div class="address-table">
    <v-data-table :headers="showHeaders"
                  :items="addressList"
                  :options.sync="options"
                  :items-per-page="perPage"
                  :server-items-length="totalItems"
                  :loading="loading"
                  id="address-list">

      <template v-slot:header.choose
                v-if="showChecks">
        <label class="checkbox">
          <input type="checkbox"
                 v-model="checked"
                 @change="checkAll" />
          <span></span>
        </label>
      </template>

      <template v-slot:header.name>
        {{$ml.get('addressName')}}
      </template>
      <template v-slot:header.address>
        {{$ml.get('address')}}
      </template>

      <template v-slot:header.email_address>
        {{$ml.get('emailAddress')}}
      </template>

      <template v-slot:header.city>
        {{$ml.get('city')}}
      </template>
      <template v-slot:header.country.data.name>
        {{$ml.get('country')}}
      </template>
      <template v-slot:header.is_invoice_address>
        {{$ml.get('is_invoice_address')}}
      </template>

      <template v-slot:header.action>
        {{$ml.get('action')}}
      </template>

      <template v-slot:item.choose="{ item }"
                v-if="showChecks">
        <label class="checkbox">
          <input type="checkbox"
                 v-model="itemIds"
                 :value="item.id" />
          <span></span>
        </label>
      </template>

      <template v-slot:item.address="{ item }">
        {{item.line1}} {{item.line2}}
      </template>
      <template v-slot:item.is_invoice_address="{ item }">
        <i :class="(item.is_invoice_address ? 'cursor-not-change' : 'cursor-change') + (item.is_invoice_address ? ' fas' : ' far')"
           class="fa-star text-primary"
           @click="setAsAddress(item)"></i>
      </template>

      <template v-slot:item.action="{ item }"
                class="p-0 actionsButton">
        <ButtonEdit class="mr-2"
                    @click.native="openEditAddressModal(item)"></ButtonEdit>
        <ButtonDelete @click.native="deleteCard(item)"></ButtonDelete>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import EventBus from '@/event-bus'

import ButtonEdit from '@/components/buttons/ButtonEdit'
import ButtonDelete from '@/components/buttons/ButtonDelete'

export default {
  name: 'AddressTable',
  props: {
    showChecks: Boolean,
  },
  data() {
    return {
      addressList: [],
      checked: false,
      itemIds: [],
      loading: false,
      options: {},
      perPage: 16,
      totalItems: 0,
      itemIdsProcessCount: 0,
      headers: [
        { text: '', value: 'choose', sortable: false, hide: true },
        { text: this.$ml.get('addressName'), value: 'name', sortable: false },
        {
          text: this.$ml.get('address'),
          value: 'address',
          sortable: false,
        },
        {
          text: this.$ml.get('emailAddress'),
          value: 'email_address',
          sortable: false,
        },
        {
          text: this.$ml.get('city'),
          value: 'city',
          sortable: false,
        },
        {
          text: this.$ml.get('country'),
          value: 'country.data.name',
          sortable: false,
        },
        {
          text: this.$ml.get('is_invoice_address'),
          value: 'is_invoice_address',
          sortable: false,
        },
        {
          text: this.$ml.get('actions'),
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ],
    }
  },
  created() {
    this.getAddressList()
  },
  mounted() {
    EventBus.$on('addAddressSuccess', () => {
      this.getAddressList()
    })

    EventBus.$on('deleteSelectedAddress', () => {
      this.deleteSelectedAddress()
    })
  },
  computed: {
    ...mapGetters(['currentUser']),
    showHeaders() {
      return this.headers.filter((item) =>
        !this.showChecks ? item.value != 'choose' : item
      )
    },
  },
  methods: {
    getAddressList() {
      this.loading = true

      //ApiService.get(`/users/${this.currentUser.user.id}/addresses`)
      ApiService.get(`accounts/addresses`)
        .then(({ data }) => {
          this.addressList = data.data

          this.totalItems = data.meta.pagination.total
          this.perPage = data.meta.pagination.count
          this.loading = false

          this.$emit('addressListData', this.addressList)
        })
        .catch(() => {
          this.addressList = []
          this.$emit('addressListData', this.addressList)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setAsAddress(item) {
      this.loading = true
      ApiService.put(
        //`users/${this.currentUser.user.id}/addresses/${item.id}/set-as-invoice-address`
        `accounts/addresses/${item.id}/set-as-invoice-address`
      ).then((res) => {
        EventBus.$emit('addAddressSuccess', true)
        this.$emit('setInvoiceAddress', true)
      })
    },
    deleteCard(item) {
      this.$bvModal
        .msgBoxConfirm(this.$ml.get('doYouWantToDeleteAddress'), {
          title: this.$ml.get('pleaseConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$ml.get('yes'),
          cancelTitle: this.$ml.get('no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true

            ApiService.delete(
              //`users/${this.currentUser.user.id}/addresses/${item.id}`
              `accounts/addresses/${item.id}`
            )
              .then(() => {
                this.getAddressList()

                this.$generateToast(
                  this,
                  'success',
                  this.$ml.get('deletedAddressSuccess')
                )
              })
              .catch((err) => {
                if (err == 'Error') {
                  this.$generateToast(
                    this,
                    'danger',
                    this.$ml.get('anErrorHasOccurred')
                  )
                }
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteSelectedAddress() {
      if (this.itemIds.length === 0) {
        this.$generateToast(this, 'danger', this.$ml.get('noSelection'))
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$ml.get('doYouWantToDeleteSelectedAddress'), {
            title: this.$ml.get('pleaseConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$ml.get('yes'),
            cancelTitle: this.$ml.get('no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true
              for (const i in this.itemIds) {
                ApiService.delete(
                  //`users/${this.currentUser.user.id}/addresses/${this.itemIds[i]}`
                  `accounts/addresses/${this.itemIds[i]}`
                )
                  .then((res) => {})
                  .catch((err) => {})
                  .finally(() => {
                    this.itemIdsProcessCount++
                  })
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    openEditAddressModal(item) {
      this.$emit('openEditAddressModal', item)
    },
    checkAll: function () {
      this.itemIds = []
      if (this.checked) {
        for (const i in this.addressList) {
          this.itemIds.push(this.addressList[i].id)
        }
      }
    },
  },
  watch: {
    itemIdsProcessCount: function (value) {
      if (this.itemIdsProcessCount > 0 && this.itemIds.length == value) {
        this.$generateToast(
          this,
          'success',
          this.$ml.get('deletedAddressSuccess')
        )
        this.getAddressList()
        this.itemIds = []
        this.checked = false
        this.itemIdsProcessCount = 0
      }
    },
  },
  components: {
    ButtonEdit,
    ButtonDelete,
  },
}
</script>

<style lang="scss" scoped>
.cursor-not-change {
  cursor: not-allowed;
}
.cursor-change {
  cursor: pointer;
}
</style>