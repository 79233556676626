<template>
  <Card class="bg-alert">
    <div class="invoice-alert d-flex flex-column flex-md-row">
      <div class="d-flex align-items-center flex-md-fill mb-3 mb-md-0">
        <GroupChatIcon color="F64E60"
                       class="mr-3" />
        <div class="text">
          <p class="font-size-lg">{{ title }}</p>
          <p class="font-size-md text-muted">
            {{ per }} Adet
            <b class="text-danger">{{ price }}TL</b>
            tutarında ödenmemiş faturanız bulunmaktadır.</p>
        </div>
      </div>
      <div class="buttons">
        <ButtonPrimary class="text-bold px-10"
                       href="#">
          Hemen Ödeme Yap
        </ButtonPrimary>
      </div>
    </div>
  </Card>
</template>

<script>
import GroupChatIcon from '@/components/icons/GroupChat'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import Card from '@/components/Card'

export default {
  name: 'InvoiceAlert',
  props: {
    title: String,
    per: Number,
    price: String,
  },
  components: {
    GroupChatIcon,
    ButtonPrimary,
    Card,
  },
}
</script>