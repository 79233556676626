<template>
  <a class="btn btn-primary px-5">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonPrimary',
}
</script>