<template>
  <!-- begin::Page loader -->
  <div class="loader-wrap">
    <!-- <div class="loader">
      <div class="spinner mb-10"
           :class="spinnerClass || 'spinner-primary'"></div>
      <slot></slot>
    </div> -->

    <content-loader>
      <slot>
        <rect x="54" y="4" width="88" height="12" rx="3" />
        <rect x="54" y="24" width="52" height="12" rx="3" />
        <rect x="0" y="56" width="400" height="12" rx="3" />
        <rect x="0" y="76" width="370" height="12" rx="3" />
        <rect x="0" y="96" width="178" height="12" rx="3" />
        <circle cx="22" cy="22" r="22" />
      </slot>
    </content-loader>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
import { mapGetters } from 'vuex'
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'Loader',
  props: {
    spinnerClass: String,
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  components: {
    ContentLoader,
  },
}
</script>

<style lang="scss" scoped></style>
