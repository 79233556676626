<template>
  <a class="btn btn-icon btn-light-primary btn-sm rounded-sm px-5">
    <i class="icon-sm fas fa-edit"></i>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonEdit'
}
</script>
